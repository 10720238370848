@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'PowerGrotesk';
    src: url('./PowerGrotesk-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  